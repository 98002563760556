import { i18n } from '@/plugins/i18n'
import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const checkList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'check/list',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('get check list err : ', err)

      return {}
    })

  return response
}
const checkGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'check/checking',
      param: `${id}/${i18n.locale}`,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.error('get check list err : ', err)

      return {}
    })

  return response
}
const checkBasicSave = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'checkq/check',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('save basic check err : ', err)

      return {}
    })

  return response
}
const checkUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'check',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('update check check err : ', err)

      return {}
    })

  return response
}
const checkCartAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/cart',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add chekcing to cart err : ', err)

      return {}
    })

  return response
}
const checkCartUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'check/cart',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('update chekcing in cart err : ', err)

      return {}
    })

  return response
}
const checkCartRemove = async (id, cartId) => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'check/cart',
      param: `${id}/${cartId}`,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('delete chekcing from cart err : ', err)

      return {}
    })

  return response
}
const checkCartList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/cartlist',
      body,
    })
    .then(res => res.data || [])
    .catch(err => {
      console.error('data chekcing cart err : ', err)

      return []
    })

  return response
}
const checkAddToReceipt = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'order/check',
      param: `${id}/${i18n.locale}`,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add check to receipt err : ', err)

      return {}
    })

  return response
}
const checkServeGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'check/serve',
      param: `${id}`,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.error('get service to check err : ', err)

      return {}
    })

  return response
}
const checkAddCertDoctor = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/cert',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add certificate from doctor err : ', err)

      return {}
    })

  return response
}
const checkAddSendBodySick = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/phrf',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add phrf from doctor err : ', err)

      return {}
    })

  return response
}
const checkAddCertSick = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/sick',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add phrf from doctor err : ', err)

      return {}
    })

  return response
}

export default {
  checkList,
  checkGet,
  checkUpdate,
  checkCartAdd,
  checkCartList,
  checkCartRemove,
  checkCartUpdate,
  checkAddToReceipt,
  checkBasicSave,
  checkServeGet,
  checkAddCertDoctor,
  checkAddSendBodySick,
  checkAddCertSick,
}
